import React from "react";
import SlideInAnimation from "../Components/SlideInAnimation";

export default function DiscordRedirect(){
    React.useEffect(() => {
        window.location.href = "https://discord.gg/radianceproject";
    }, [])
    return (
        <>
            <p className="redirect_discord_url">If you are not redirected automatically, follow this <a href="https://discord.gg/radianceproject">link</a>.</p>
            <SlideInAnimation />
        </>
    )

}