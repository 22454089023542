import React from "react";
import { motion, AnimatePresence } from "framer-motion"

import modalClose from "../images/modal_close.svg";
import Backdrop from "./Navbar/modals/Backdrop";

export default function AgreementModal({agreementActive, closeAgreement, setCheckboxActive}){
    return(
        <AnimatePresence initial={false}>

            {agreementActive && 

            <Backdrop closeAnimation={closeAgreement}>
                <div className="agreement-container">
                    <motion.div 
                        className={`agreement-rules`}
                        onClick={(e) => e.stopPropagation()}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        key={agreementActive}
                    >
                        <div className="agreement-modal-top">
                            <span className="agreement-top-title">Agreement rules</span>
                            <button onClick={closeAgreement}><img src={modalClose} alt="" /></button>
                        </div>
                        <div className="rules-scroll">
                            <p><span>1.</span> We do not refund funds if your pc does not match the description of the software. (Carefully read the description of the software you buy).</p>
                            <p><span>2.</span> If you bought the wrong product, we will not refund. (We make an exchange with an extra charge if the price is higher, in rare cases).</p>
                            <p><span>3.</span> We do not refund funds if, after the update, the cheat went into detection. (All software of the code will go into detection at some point, but after updating the software, you can claim compensation).</p>
                            <p><span>4.</span> We do not refund funds if some functions stop working after the update.</p>
                            <p><span>5.</span> We do not refund funds if you bought a key outside the region and were banned, if you are not from Russia or the CIS countries and bought a RU and CIS key.</p>
                            <p><span>6.</span> When you activate the license key, it binds to your computer, and transferring it to another computer is not possible under any conditions. Attempting to scam staff may result in a ban from all our products.<br />1 License Key = 1 PC.</p>
                            <p><span>7.</span> Unwillingness to fulfill the requirements for the software to work = refusal of support (Money-back in this case is not done).</p>
                        </div>
                        <span href="#" className="subscription-agreement-accept" onClick={() => {
                            setCheckboxActive();
                            closeAgreement()
                        }}>Accept</span>
                    </motion.div>
                </div>
            </Backdrop>}
        </AnimatePresence>
    )
}